body {
    background: #000 !important;
}

.block_image {
    width: 100%;
    height: auto;
}

.c-logo {
    width: 250px;
    margin-top: 25px;
    margin-bottom: 20px;
}
h1 {
    font-family: 'Bebas Neue', sans-serif; 
    color: white;
    font-size: 40px;
}
p {
    color: white;
}
.c-image-container {
    width: 100%;
    height: 100vh;

    input[type=checkbox] {
        display: none;
      }
      
    img {
        transition: transform 0.25s ease;
        cursor: zoom-in;
      }
      
    input[type=checkbox]:checked ~ label > img {
        transform: scale(2);
        cursor: zoom-out;
      }
    label {
        width: 100%;
        height: auto;
        max-height: 100vh;
    }

}
